<div class="container">
    <div class="label d-flex justify-content-between mat-dialog-title" mat-dialog-title>
        <h4 class="text">{{'Download Users Details'}}</h4>
        <mat-icon style="color: red; margin-right: 10px" mat-dialog-close="false">close</mat-icon>
    </div>
    <div class="button-container d-flex justify-content-between">
        <mat-form-field appearance="outline" class="col-md-6" [style.fontSize.px]="12">
                <mat-label>{{'Email'|translate}}</mat-label>
                <input matInput type="text"
                       placeholder="{{'Enter Email'|translate}}" name="phone"
                       [formControl]="email"  required>
                <mat-error class="modal-error" *ngIf="email.hasError('pattern')">{{'Enter a valid email '|translate}}</mat-error>
                <mat-error class="modal-error" *ngIf="email.hasError('required')">{{'Email is required'|translate}}</mat-error>
            </mat-form-field>
        <button class="button" (click)="submitForm(email.value)" [disabled]="email.invalid">{{'Download' |translate}}</button>
    </div>
</div>
