import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MessageService} from "../../../services/message.service";
import {RestApiService} from "../../../services/rest-api.service";
import {UtilsService} from "../../../services/utils.service";

@Component({
    selector: 'app-client-apps-assignment',
    templateUrl: './client-apps-assignment.component.html',
    styleUrls: ['./client-apps-assignment.component.css']
})
export class ClientAppsAssignmentComponent implements OnInit {
    programs = [];
    allClientApps = [];
    @Output() appAssigned = new EventEmitter()

    constructor(private messageService: MessageService, private apiService: RestApiService, private utils: UtilsService) {
    }

    ngOnInit() {
        this.getClientApps();
    }

    setAssignedApps(): void {
        if (this.programs.length) {
            const data = {client_app_ids: this.programs, user_id: this.utils.userId}
            this.apiService.setClientApp(data).subscribe((response: any) => {
                this.messageService.closableSnackBar('Client apps assigned Successfully to this user', 5000);
                this.appAssigned.emit(true);
            }, error => {
                this.messageService.errorSnackBar(error.error.message, 5000);
            });
        } else {
            this.messageService.closableSnackBar('Please Select at least One App', 500);
        }
    }

    appSelected(id, programIndex, event): void {
        if (event.target.checked) {
            this.programs.push(id);
        } else {
            if (programIndex > -1) {
                this.programs.splice(programIndex, 1);
            }
        }
    }


    getClientApps(): void {
        const userId = this.utils.userId;
        this.apiService.clientApps(userId).subscribe(response => {
            const res = response as any;
            this.allClientApps = res.data;
        }, error => {
            this.messageService.errorSnackBar(error.error.message, 5000);
        })
    }

    cancelAppAssignment(): void {
        this.appAssigned.emit(false);
    }
}
