<div #myDiv>
    <mat-drawer-container class="example-margin app-assignment-container" autosize>
        <mat-drawer mode="side" opened style="background: #33404D; width: 300px; min-height: 80vh">
            <div class="nav-bar-content d-flex flex-column no-app" *ngIf="!isLoading">
                <div class="d-flex add-app-title {{getActiveAppClass(app.client_id)}} justify-content-between align-items-center" *ngFor="let app of userApps; let i = index">
                        <span class="cursor-pointer app-selected"
                              routerLinkActive="app-selected" (click)="setActiveApp(app.client_id, app.name, app.multiple_tags_allowed, app.id)">{{app.name}}
                        </span>
                    <span class="img w-10" *ngIf="mode !== 'view'">
                                <img src="../../../../assets/images/delete.png" alt="" class="delete-icon cursor-pointer" (click)="openDeleteDialog(app.client_id, i)">
                            </span>
                </div>
                <span class="add-app-title" *ngIf="!userApps?.length">{{'No Apps Assigned'|translate}}</span>
                <span class="add-app-subtitle mb-2" (click)="assignApps()" *ngIf="this.mode !== 'view'">{{'Click + to assign a application'|translate}}
                    <button mat-button class="add-app-button"><mat-icon class="plus-icon" svgIcon="plus"></mat-icon></button>
                </span>
            </div>
        </mat-drawer>
        <mat-drawer-content class="user-tags pl-3 pr-3">
                <ng-container *ngIf="!isLoading && manageTag">
                    <div class="selected-app w-100 d-flex align-items-center mb-3 justify-content-between">
                        <h3 class="ml-1">{{appName |translate}}</h3>
<!--                        <div *ngIf="mode !== 'view'">-->
<!--                            <mat-label class="selected-app mr-3">{{'Archive'}}</mat-label>-->
<!--                            <mat-slide-toggle (change)="toggleClientApp($event, this.clientId.value)" [checked]="appDisabled" class="selected-app"></mat-slide-toggle>-->
<!--                        </div>-->
                    </div>
                    <mat-accordion class="example-headers-align mat-elevation-z0 expansion mb-3"  multi="false" #accordion="matAccordion">
                        <ng-container *ngFor="let location of userTagsLocations; let i = index">
                            <mat-expansion-panel  class="mb-2"  [expanded]="expanded[i]" hideToggle (afterExpand)="editUserTag(location.user_tag_id, location.data_level_id, i)">
                                <mat-expansion-panel-header class="d-flex justify-content-between">
                                    <mat-panel-title>{{location.data_level_name |translate}}</mat-panel-title>
                                    <img src="../../../../assets/images/arrow-down.png" class="toggle-image" alt="down">
                                </mat-expansion-panel-header>
                                <div class="button-container d-flex p-1 mb-2 justify-content-end" *ngIf="mode !== 'view'">
                                    <button mat-flat-button class="delete action" (click)="deleteUserTag(location.user_tag_id, i)">{{'Delete' | translate}}</button>
                                </div>
                                <app-user-tag [clientId]="this.clientId.value" (userTagCreated)="getUserTagsLocation($event)" [dataLevelId]="dataLevel" [userTagId]="userTagId" *ngIf="expanded[i]" [mode]="mode"></app-user-tag>
                            </mat-expansion-panel>
                        </ng-container>
                        <p class="alert-danger mt-2 d-flex align-items-center" *ngIf="checkDataEntryTag()"><mat-icon matPrefix class="info-icon">info</mat-icon>Only one tag is allowed for This Client App</p>
                        <mat-expansion-panel  class="mb-2"  [expanded]="leveAssignment" hideToggle (opened)="addTag()" *ngIf="!checkDataEntryTag() && userApps?.length && mode !== 'view'" (closed)="leveAssignment = false">
                            <mat-expansion-panel-header class="d-flex justify-content-between">
                                <mat-panel-title>{{'Add New Tag' |translate}}</mat-panel-title>
                                <img src="../../../../assets/images/arrow-down.png" class="toggle-image" alt="down">
                            </mat-expansion-panel-header>
                            <div class="mt-2 w-100" *ngIf="leveAssignment">
                                <app-user-tag [clientId]="this.clientId.value" (userTagCreated)="getUserTagsLocation($event)" *ngIf="manageTag && !checkDataEntryTag()" [allottedLevel]="this.allottedLevel"></app-user-tag>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
            <ng-container *ngIf="isLoading">
                <app-loader [loading]="isLoading"></app-loader>
            </ng-container>
                <app-client-apps-assignment (appAssigned)="appAssigned($event)" *ngIf="appAssignment"></app-client-apps-assignment>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
