import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {RestApiService} from "../../../services/rest-api.service";
import {FormBuilder, FormControl} from "@angular/forms";
import {MessageService} from "../../../services/message.service";
import {UtilsService} from "../../../services/utils.service";
import {ThemePalette} from "@angular/material/core";
import {MatDialog} from "@angular/material/dialog";
import {RuleAccessPopupComponent} from "../../../shared/rule-access-popup/rule-access-popup.component";


@Component({
    selector: 'app-app-assignment',
    templateUrl: './app-assignment.component.html',
    styleUrls: ['./app-assignment.component.css']
})
export class AppAssignmentComponent implements OnInit {
    @ViewChild('myDiv') myDiv!: ElementRef;
    clientId = new FormControl('')
    dataLevel = '';
    userTagId = '';
    color: ThemePalette = 'accent';
    appName = '';
    appId: any;
    clientApps: any;
    userTagsLocations = [];
    currentChoice: string;
    leveAssignment = false;
    userApps: any[];
    appAssignment = false;
    allottedLevel = [];
    manageTag = false;
    isLoading = false;
    multipleTagAllowed: boolean;
    expanded = {};
    accordionIndex: any;
    @Input() mode: string;

    constructor(private apiService: RestApiService, private formBuilder: FormBuilder, private messageService: MessageService,
                private utils: UtilsService, private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.getUserClientApp();
    }


    assignApps() {
        this.manageTag = false;
        this.appAssignment = true
    }

    getUserClientApp() {
        this.isLoading = true;
        this.apiService.userClientApps(this.utils.userId).subscribe((response: any) => {
            this.manageTag = true;
            this.isLoading = false;
            this.userApps = response.data;
            this.setActiveApp(response.data[0]?.client_id, response.data[0]?.name, response.data[0]?.multiple_tags_allowed, response.data[0]?.id);
            this.appName = response.data[0]?.name;
            this.appId = response.data[0]?.id;
            if (this.userApps.length) {
                this.appAssignment = false;
                this.manageTag = true;
            } else {
                this.manageTag = false;
            }
        }, error => {
            this.messageService.errorSnackBar(error.error.message, 5000);
            this.isLoading = false;
        })
    }


    // ##<-------- Set Active App in Nav Bar ------->
    getActiveAppClass(choice): any {
        if (this.currentChoice == choice)
            return "active";
        else
            return "not";
    }

    setActiveApp(choice: string, appName: string, multiple_tags_allowed: boolean, appId: string): void {
        this.currentChoice = choice;
        this.clientId.setValue(choice);
        this.multipleTagAllowed = multiple_tags_allowed;
        this.expanded[this.accordionIndex] = false;
        this.checkDataEntryTag();
        this.manageTag = true;
        this.appName = appName
        this.appId = appId
        this.appAssignment = false;
        this.getUserTagsLocation();
    }

    getUserTagsLocation(changes = true): void {
        this.isLoading = true;
        this.expanded[this.accordionIndex] = false;
        this.leveAssignment = false;
        if (changes) {
            this.apiService.userLocations(this.utils.userId, this.clientId.value).subscribe(response => {
                const res = response as any;
                this.userTagsLocations = res.data;
                this.allottedLevels();
                this.isLoading = false;
            }, error => {
                this.messageService.errorSnackBar(error.error.message, 5000);
            });
        }
    }


    editUserTag(userTagId, dataLevelId, index) {
        this.leveAssignment = false;
        if (userTagId) {
            this.accordionIndex = index;
            this.userTagId = userTagId;
            this.dataLevel = dataLevelId;
            this.expanded[this.accordionIndex] = true;
        }
    }


    toggleClientApp(event, clientId): void {
        const data = {status: event.checked, user_id: this.utils.userId, client_app_id: clientId}
        this.apiService.toggleClientApp(data).subscribe(response => {
            this.messageService.closableSnackBar(response.message);
        }, error => {
            this.messageService.errorSnackBar(error.error.message, 5000);
        });
    }

    deleteUserTag(userTagId: any, i: number): void {
        this.apiService.deleteUserTag(userTagId, this.appId).subscribe(response => {
            if (response) {
                this.userTagsLocations.splice(i, 1);
                this.allottedLevel.splice(i, 1)
                this.messageService.closableSnackBar(response.message, 5000);
            }
        }, error => {
            this.messageService.errorSnackBar(error.error.message, 5000);
        });
    }

    toggleUserTag(event, userTagId): void {
        const data = {status: event.checked, user_tag_id: userTagId}
        this.apiService.toggleUserTag(data).subscribe(response => {
            const res = response as any;
            this.messageService.closableSnackBar(res.message, 5000);
        }, error => {
            this.messageService.errorSnackBar(error.error.message, 5000);
        });
    }


    appAssigned(status) {
        this.manageTag = true;
        this.appAssignment = false;
        this.getUserTagsLocation();
        if (status) {
            this.getUserClientApp()
        }
    }

    allottedLevels() {
        this.allottedLevel = [];
        this.userTagsLocations.forEach(location => {
            this.allottedLevel.push(location);
        });
    }

    checkDataEntryTag() {
        let tagLength: boolean;
        tagLength = (!this.multipleTagAllowed && this.allottedLevel.length > 0)
        return tagLength
    }

    deleteClientApp(clientId, index): void {
        this.isLoading = true;
        this.apiService.deleteClientApps(this.utils.userId, clientId).subscribe((response: any) => {
            if (response) {
                this.messageService.closableSnackBar(response.message)
                this.userApps.splice(index, 1);
                this.setActiveApp(this.userApps[0]?.client_id, this.userApps[0]?.name, this.userApps[0]?.multiple_tags_allowed, this.userApps[0]?.id )
            }
        }, error => {
            this.messageService.closableSnackBar(error);
            this.isLoading = false;
        });
    }

    addTag(): void {
        this.leveAssignment = true;
       this.expanded[this.accordionIndex] = false;
    }
    openDeleteDialog(clientId, index): void {
        const dialogRef = this.dialog.open(RuleAccessPopupComponent, {
            disableClose: false,
            width: '350px',
            data: {message: 'Are you sure to delete this client app' }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.deleteClientApp(clientId, index)
            }
        });
    }

}
