import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, Validators} from "@angular/forms";
import {UtilsService} from "../../../services/utils.service";

@Component({
  selector: 'app-download-options',
  templateUrl: './download-options.component.html',
  styleUrls: ['./download-options.component.css']
})
export class DownloadOptionsComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.pattern(this.utils.emailPattern)])
  radioButtons: any = [
    { label: 'Excel', value: 'excel', checked: false },
    { label: 'Pdf', value: 'pdf', checked: false },
    { label: 'Csv', value: 'csv', checked: false }
  ];
  type;
  constructor(public dialogRef: MatDialogRef<DownloadOptionsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private utils: UtilsService) { }

  ngOnInit(): void {
  }
  submitForm(result): void {
    this.dialogRef.close(result);
  }

}
