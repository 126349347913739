import {Component, OnInit, Optional} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RestApiService} from '../../services/rest-api.service';
import {UtilsService} from "../../services/utils.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-manage-user',
    templateUrl: './manage-user.component.html',
    styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {
    userDetails: any;
    ssoDetails: boolean = true;
    manageUser = false;
    viewMode = false;
    title = 'SSO Details'
    constructor(private route: ActivatedRoute, private apiService: RestApiService, private utils: UtilsService, @Optional() public dialogRef: MatDialogRef<ManageUserComponent>,) {
    }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            if (params.get('id')) {
                this.getUserDetails(params.get('id'))
                this.viewMode = false;
            } else {
                this.viewMode = true;
                this.getUserDetails(this.utils.userId);
            }
        });
    }

    getUserDetails(value): void {
        this.apiService.searchUser(value, '').subscribe(response => {
            const res = response as any;
            this.userDetails = res.data;
            this.utils.userId = res.data.id;
            this.manageUser = true;
        });
    }

    changeDetailsType(): void {
        this.ssoDetails = !this.ssoDetails

    }
}
