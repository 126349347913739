 <ng-container *ngIf="allClientApps.length; else noApps">
        <div class="selected-app col-md-12 d-flex align-items-center justify-content-between">
            <h3 class="ml-1">{{'Selected Programs/ Apps'|translate}}</h3>
            <div class="d-flex">
                <button mat-button class="cancel-button action mr-3"
                        (click)="cancelAppAssignment()">{{'Cancel'|translate}}</button>
                <button mat-button class="button"
                        (click)="setAssignedApps()">{{'Save Apps'|translate}}</button>
            </div>
        </div>
        <div class="content">
            <div class="cont-checkbox ml-3" *ngFor="let element of allClientApps; index as i">
                <input matInput type="checkbox" [id]="'myCheckbox-' + i"
                       [checked]="programs.includes(element.id)"
                       (change)="appSelected(element.client_id, i, $event)" checked/>
                <label [for]="'myCheckbox-' + i"><img [src]="element.app_icon" alt="icon"/>
                    <span class="cover-checkbox">
                       <svg viewBox="0 0 12 10"><polyline points="1.5 6 4.5 9 10.5 1"></polyline></svg>
                   </span>
                    <div class="info">{{ element.name }}</div>
                </label>
            </div>
        </div>
    </ng-container>
    <ng-template #noApps>
        <div class="d-flex justify-content-center align-content-center flex-wrap selected-app mt-5">
            <h3 class="ml-1">{{'There are no apps assigned to you. Please contact admin'|translate}}</h3>
        </div>
    </ng-template>
