import {Component, Inject, OnInit} from '@angular/core';
import {ngxCsv} from "ngx-csv";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormControl, Validators} from "@angular/forms";
import {UtilsService} from "../../../services/utils.service";
import {MessageService} from "../../../services/message.service";
import {RestApiService} from "../../../services/rest-api.service";

@Component({
    selector: 'app-upload-users',
    templateUrl: './upload-users.component.html',
    styleUrls: ['./upload-users.component.css']
})
export class UploadUsersComponent implements OnInit {
    email = new UntypedFormControl('', [Validators.required, Validators.pattern(this.utils.emailPattern)]);
    loader = false;
    formData = new FormData();
    isRoleDelete: boolean = false;
    title: string;

    constructor(public dialogRef: MatDialogRef<UploadUsersComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any, private utils: UtilsService, private messageService: MessageService, private apiService: RestApiService) {
    }

    ngOnInit(): void {
        this.checkActionType();
    }

    checkActionType(): void {
        if (this.data.type === 'delete') {
            this.isRoleDelete = true;
            this.title = 'Upload Role Deletion File'
        } else {
            this.title = 'Upload SSO Users'
        }
    }

    downloadFormat(): any {
        if (!this.isRoleDelete) {
            const csvHeaders = ['Action (disable/delete_app/create/manage_team/update)', 'User Id', 'Application', 'State', 'Name', 'Email', 'Phone',
                'Location Type', 'Location Name/Number', 'Location Filter ( Zila / Assembly Constituency Number )',
                'User Permissions', 'Unit Permissions(List Data Unit name with the highest permission level to give the Unit access for all data levels.)', 'Data Level', 'Data Type', 'Bypass OTP', 'Carousel Id', 'Team allowed', 'User Rank'];
            const csvContent = [];
            csvContent.push(['create', '', 'Saral', 'Bihar', 'App User', 'app_user@gmail.com', '9900999009', 'Mandal', 'Mandal Name', 'Zila Name',
                '{BoothData: [\'Create\'], PradeshData: [\'Create\'], ZilaData: [\'Create\'], BoothStrengtheningProgram: [\'Create\'],}',
                '{}', 'Mandal', 'Karyakarta', 'false', '', 'true/false'],['update','','','','','','5111575701','','','','{}','{}','','','','','','1'],
                ['delete_app','','Sangathan Data Entry','','','','5111575701','','','','{}','{}','','','','','',''],
                ['disable','','','','','','5111575701','','','','{}','{}','','','','','','']);
            return new ngxCsv(csvContent, 'SsoUploadFormat', {headers: csvHeaders});
        } else {
            const csvHeaders = ['Phone Number', 'Role'];
            const csvContent = [];
            csvContent.push(['9090909090', 'national_manager',]);
            return new ngxCsv(csvContent, 'user-role-delete-format', {headers: csvHeaders});
        }
    }

    onFileChange(event, control, elementRef = null) {
        if (event.target.files && event.target.files.length) {
            if (event.target.files[0].type === 'text/csv') {
                const [file] = event.target.files;
                this.formData.append('file', file);
            } else {
                this.formData.delete('file');
                this.messageService.errorSnackBar('Selected File is not a valid format');
            }
        }
    }

    upload(): void {
        if (this.formData.get('file')) {
            this.loader = true;
            this.formData.append('email', this.email.value);
            if (!this.isRoleDelete) {
                this.apiService.uploadSsoUsers(this.formData).subscribe((response: any) => {
                    this.loader = false;
                    this.email.reset('')
                    this.messageService.closableSnackBar(response.message);
                    this.dialogRef.close();
                }, error => {
                    this.loader = false;
                    this.messageService.errorSnackBar(error);
                });
            } else {
                this.apiService.uploadRoleDeletion(this.formData).subscribe((response: any) => {
                    this.loader = false;
                    this.email.reset('')
                    this.messageService.closableSnackBar(response.message);
                    this.dialogRef.close();
                }, error => {
                    this.loader = false;
                    this.messageService.errorSnackBar(error);
                });
            }
        } else {
            this.messageService.errorSnackBar('No File has been Selected');
        }
    }

    getFileName() {
        if (this.formData.get('file')) {
            // @ts-ignore
            return this.formData.get('file').name;
        }
    }
}
