import {Component} from '@angular/core';
import {RestApiService} from "../services/rest-api.service";
import {UtilsService} from "../services/utils.service";
import {MessageService} from "../services/message.service";
import * as Constant from '../constant';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})

export class UserProfileComponent {
  userDetails = [];
  userName: string;
  userRoles: any;
  croppedImage = ''
  clientApps: any;
  isLoading = false;
  states = [];
  constructor(private apiService: RestApiService,
              public utils: UtilsService, private messageService: MessageService) {
  }
  ngOnInit(): void {
    this.userName = localStorage.getItem(Constant.USERNAME);
    this.userRoles = localStorage.getItem(Constant.USERROLE)
    // @ts-ignore
    this.croppedImage = localStorage.getItem(Constant.USER_IMAGE).replaceAll('"', '');
    this.userDetails = [{label: 'Mobile Number', value: localStorage.getItem(Constant.USERPHONE)}, {label: 'Email', value: localStorage.getItem(Constant.USEREMAIL)}];
    this.getUserClientApps();
    this.getCountryStates();
  }
  getUserClientApps(): void {
    this.isLoading = true;
    this.apiService.clientApps().subscribe(response => {
      const res = response as any;
      this.clientApps = res.data;
      this.isLoading = false;
    });
  }
  getCountryStates(): void {
    this.apiService.allottedCountryStates().subscribe(response => {
      const res = response as any
      this.states = res.data;
    }, error => {
      this.messageService.errorSnackBar(error.error.message, 5000);
    });
  }
}
