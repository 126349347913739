<div class="label d-flex justify-content-between mat-dialog-title w-100" mat-dialog-title>
    <h4 class="text">{{title}}</h4>
    <mat-icon style="color: red; margin-right: 10px" mat-dialog-close="">close</mat-icon>
</div>
<mat-dialog-content  class="p-3">
<div class="container">
<span class="d-flex justify-content-between align-content-center flex-wrap">
    <mat-form-field appearance="outline" [style.fontSize.px]="12" class="col-8">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Email"
               [formControl]="email"
               name="email"
               required pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$">
    </mat-form-field>

    <button mat-button class="format-button" (click)="downloadFormat()"><mat-icon class="download-icon"
                                                                                  svgIcon="download"
                                                                                  matPrefix></mat-icon>Format</button>
      <div class="file-container d-flex justify-content-between align-content-center mt-3">
   <mat-icon class="download-icon" svgIcon="upload" matPrefix></mat-icon>
        <span class="file-name"
              matTooltip="{{getFileName() ? getFileName() : ''}}">{{getFileName() ? getFileName() : 'Upload File'}}</span>
        <button mat-raised-button class="upload-button" (click)="fileInput.click()">
          <span *ngIf="!loader" class="button-span">{{'Select File' | translate}}</span>
          <span class="upload-img-input">
            <input type="file" style="margin: inherit;" (change)="onFileChange($event, 'file')" accept=".csv"
                   #fileInput/>
          </span>
          <mat-spinner class="icon-color" *ngIf="loader" [diameter]="20"></mat-spinner>
        </button>
      </div>
</span>
    <span class="d-flex justify-content-center w-100 mt-3">
        <button mat-button class="button" (click)="upload()" [disabled]="email.invalid">Submit</button>
    </span>
</div>
</mat-dialog-content>
