<div class="container-fluid mt-2">
    <div class="mat-elevation-z0 user-table-container" *ngIf="users.length">
        <table class="user-table">
            <thead>
            <tr>
                <th>{{'S.No' | translate}}</th>
                <th class="text-left">{{'Name' | translate}}</th>
                <th class="text-left">{{'Username' | translate}}</th>
                <th class="text-left">{{'Email' | translate}}</th>
                <th>{{'Phone No.' | translate}}</th>
                <th>{{'Calling Role' | translate}}</th>
                <th class="text-center w-72">{{'Action' | translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of users; let i = index">
                <td>{{offset + i + 1}}</td>
                <td class="text-left">{{user.name}}</td>
                <td class="text-left">{{user.username}}</td>
                <td class="text-left">{{user.email}}</td>
                <td>{{user.phone_number}}</td>
                <td>{{user.role_name?.split('_').join(' ')}}</td>
                <td class="action-group text-center justify-content-center">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editUser(user.phone_number, user.id)" *ngIf="!user.disabled">
                            <mat-icon svgIcon="edit-user"></mat-icon>
                            {{'Edit'|translate}}
                        </button>
                        <button mat-menu-item (click)="viewSsoDetails(user.id)">
                            <mat-icon svgIcon="view-sso"></mat-icon>
                            {{'View'|translate}}
                        </button>
                        <button mat-menu-item (click)="openDialog(user.active,'activate', user.id)" *ngIf="!user.disabled">
                            <mat-icon svgIcon="deactivate-user"></mat-icon>
                            <ng-container *ngIf="user.active; else active">{{'Deactivate'|translate}}</ng-container>
                            <ng-template #active>{{'Activate' |translate}}</ng-template>
                        </button>
                        <button mat-menu-item (click)="openDialog(user.disabled,'disable', user.id)" *ngIf="!user.disabled">
                            <mat-icon svgIcon="delete-user"></mat-icon>{{'Disable'|translate}}
                        </button>
                        <button mat-menu-item (click)="openDialog(user.disabled,'disable', user.id)" *ngIf="user.disabled">
                            <mat-icon svgIcon="delete-user"></mat-icon>{{'Enable'|translate}}
                        </button>
                    </mat-menu>
                </td>

            </tr>
            </tbody>
        </table>
    </div>
    <div class="d-flex justify-content-center row" *ngIf="!users.length">
        <div class="col-12 d-flex justify-content-center">
            <img src="../../../../assets/images/no-data.png" alt="" style="height: 100px">
        </div>
        <div class="d-flex justify-content-center mt-3 mb-5">
            <p class="no-data">No Data Found</p>
        </div>
    </div>
    <div class="paginator" *ngIf="users.length">
        <button mat-stroked-button color="primary" (click)="nextPage()" [disabled]="this.users.length < this.pageSize " >Next <mat-icon matSuffix>navigate_next</mat-icon></button>
        <button mat-stroked-button color="primary" (click)="prevPage()" [disabled]="this.pageNumber === 1"><mat-icon matPrefix>navigate_before</mat-icon> Previous</button>
        <mat-chip-list>
            <mat-chip><span [style]="'font-weight: 400; font-size: 15px;'">Total:&nbsp;&nbsp;</span><span
                    style="font-weight: 400; font-size: 25px;'">{{ length }}</span>
                <button [disabled]="isLoading" matChipRemove matTooltip="Refresh here to get the latest count">
                    <mat-icon *ngIf="!isLoading" (click)="userListCount()">refresh</mat-icon>
                    <mat-icon *ngIf="isLoading" ><img alt="" ngSrc="assets/gifs/spinning-circle.gif" height="48" width="48"></mat-icon>
                </button>
            </mat-chip>
        </mat-chip-list>
    </div>
</div>


