import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UtilsService} from "../../../services/utils.service";
import {MessageService} from "../../../services/message.service";
import {RestApiService} from "../../../services/rest-api.service";
import {ConfirmDialogComponent} from "../../../shared/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {LoaderService} from "../../../services/loader.service";


@Component({
    selector: 'app-user-tag',
    templateUrl: './user-tag.component.html',
    styleUrls: ['./user-tag.component.css']
})
export class UserTagComponent implements OnInit, OnChanges {
    userTags: FormGroup = new FormGroup({
        user_tag_id: new FormControl(''),
        client_id: new FormControl('', [Validators.required]),
        user_id: new FormControl(this.utils.userId, [Validators.required]),
        data_level_id: new FormControl('', [Validators.required]),
        location_type: new FormControl('', [Validators.required]),
        location_ids: new FormControl('', [Validators.required]),
        app_permission_ids: new FormControl('', [Validators.required]),
        is_rbs_tag: new FormControl(false)
    });
    @Input() clientId: any;
    @Output() userTagCreated = new EventEmitter();
    @Input() allottedLevel: any;
    @Input() userTagId: any;
    @Input() dataLevelId: any;
    @Input() mode: string;
    levelHierarchy: any;
    isRbsTag: boolean;
    isLoading: boolean = false;
    appDataLevels = [];
    locationsDropdown = {};
    allPermissions = [];
    appPermissions: any;
    selectedPermissions: any = [];
    locations = [];
    loader = false;
    selectedLocations = []
    parentLocations = []
    selectedLocationName = [];
    selectedLevelName: any = ''
    dropDowns: any[] = [];

    constructor(public utils: UtilsService, private formBuilder: FormBuilder, private messageService: MessageService,
                private apiService: RestApiService, private dialog: MatDialog, private loaderService: LoaderService) {
    }

    ngOnInit() {
        this.onFormChange();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['clientId'] && changes['clientId'].currentValue) {
            this.userTags.get('client_id').setValue(changes['clientId'].currentValue)
            this.getAppDataLevel(changes['clientId'].currentValue);
        }
        if (changes['userTagId']) {
            if (changes['userTagId'].currentValue) {
                if (changes['clientId']) {
                    this.userTags.get('client_id').setValue(changes['clientId'].currentValue)
                }
                if (changes['dataLevelId']) {
                    this.setValues(changes['userTagId'].currentValue, changes['dataLevelId'].currentValue)
                }
            }
        }
    }

    onFormChange(): void {
        this.userTags.get('data_level_id').valueChanges.subscribe(value => {
            this.locationsDropdown = {}
            this.userTags.get('app_permission_ids').setValue('');
            this.userTags.get('location_ids').setValue('');
            this.selectedPermissions = [];
            this.selectedLocations = [];
            this.selectedLocationName = [];
            this.selectedPermissions = [];
            this.parentLocations = [];
            this.clearNgSelect(0);
            if (value) {
                this.appDataLevels.forEach(level => {
                    if (level.id === value) {
                        this.userTags.get('location_type').setValue(level.level_class);
                    }
                });
                this.getDataLevelHierarchy(value);
                this.getCountryStates();
                this.getAppPermissions(value);
                this.selectedLevelName = this.utils.returnSelectedValue(value, this.appDataLevels);
            }
        });

    }

    getDataLevelHierarchy(levelId): void {
        this.loader = true;
        this.loaderService.show();
        this.apiService.dataLevelHierarchy(levelId).subscribe((response: any) => {
            this.levelHierarchy = response.data;
            this.loaderService.hide();
            this.loader = false;
        }, error => {
            this.loaderService.hide();
            this.loader = false;
            this.messageService.errorSnackBar(error);
        });
    }

    getAppDataLevel(clientAppId, levelClass = null): void {
        this.loader = true;
        this.apiService.dataLevel(clientAppId).subscribe((res: any) => {
            this.appDataLevels = res.data;
            this.appDataLevels = this.utils.deleteExtraLevel(this.appDataLevels, ['TalukaPanchayat', 'GramPanchayat', 'Ward']);
            this.loader = false;
            if (levelClass) {
                this.appDataLevels.forEach(level => {
                    if (level.id === levelClass) {
                        this.userTags.get('location_type').setValue(level.level_class);
                    }
                })
            }
        }, error => {
            this.messageService.errorSnackBar(error);
            this.loader = false;
        });
    }

    getCountryStates(): void {
        this.apiService.allottedCountryStates().subscribe((response: any) => {
            // for handling data level National,
            // special case in which we have to show only National country state
            this.locationsDropdown['CountryState'] = response.data;
            if (this.selectedLevelName === 'National') {
                this.locationsDropdown['CountryState'] = response.data.filter(value => {
                    return value.name === this.selectedLevelName;
                })
            }
        }, error => {
            this.messageService.errorSnackBar(error);
        });
    }

    getAppPermissions(dataLevel): void {
        this.loader = true;
        this.loaderService.show();
        this.apiService.appPermission(this.userTags.get('client_id').value, dataLevel, this.utils.userId, this.userTagId ? this.userTagId: '').subscribe(response => {
            this.appPermissions = Object.values(response)[1];
            this.allPermissions = response.all_permissions_ids;
            this.loader = false;
        }, error => {
            this.loaderService.hide();
            this.loader = false;
            this.messageService.errorSnackBar(error);
        });
    }

    getValue(items: any): any {
        return items;
    }

    permissionSelection(event: any, id): void {
        if (event.checked) {
            this.selectedPermissions.push(id);
        } /* unselected */ else {
            let i = 0;
            this.selectedPermissions.forEach((value: any) => {
                if (value === id) {
                    this.selectedPermissions.splice(i, 1);
                    return;
                }
                i++;
            });
        }
        this.userTags.get('app_permission_ids').setValue(this.selectedPermissions);
    }

    getRequiredLocation(locationType, location, requiredLocationType, currentIndex): void {
        if (currentIndex < this.dropDowns.length - 1) {
            this.clearNgSelect(currentIndex + 1);
        }
        if (locationType !== requiredLocationType) {
            this.loaderService.show();
            this.selectedLocations = [];
            this.selectedLocationName = [];
            this.parentLocations = [];
            this.apiService.requiredLocation(locationType, location.id, requiredLocationType, location.id, location.id).subscribe((response: any) => {
                this.locations = response.data.locations;
                this.loaderService.hide();
                this.locationsDropdown[requiredLocationType] = response.data.locations;
            }, error => {
                this.loaderService.hide()
                this.messageService.errorSnackBar(error);
            })
        } else if (locationType === requiredLocationType) {
            this.setLocationIds(location.id, requiredLocationType);
        }
    }

    setLocationIds(id, locationType): void {
        if (this.selectedLocations.includes(id)) {
            return;
        } else {
            this.locationsDropdown[locationType].forEach(location => {
                if (location.id === id) {
                    this.selectedLocations.push(location.id);
                    this.selectedLocationName.push(location.number ? location.number + ' - ' + location.name : location.name)
                    this.userTags.get('location_ids').setValue(this.selectedLocations);
                } else {
                    return;
                }
            });
        }
    }

    addUserTags(): void {
        this.loaderService.show();
        this.isLoading = true;
        this.apiService.updateUserTags(this.userTags.value).subscribe(response => {
            if (response) {
                this.loaderService.hide();
                this.messageService.closableSnackBar('User Tags Has been Created Successfully', 5000)
                this.resetFormFields(true);
                this.isLoading = false;
                this.userTagCreated.emit(true);
            }
        }, error => {
            this.isLoading = false;
            this.messageService.errorSnackBar(error);
        });
    }

    resetFormFields(changes = false): void {
        this.userTags.get('user_tag_id').setValue('');
        this.userTags.get('data_level_id').reset('');
        this.userTags.get('location_ids').reset('');
        this.userTags.get('app_permission_ids').reset('');
        this.userTags.get('location_type').reset('');
        this.selectedLocations = [];
        this.selectedLocationName = [];
        this.selectedPermissions = [];
        this.clearNgSelect(0);
    }


    remove(index): void {
        this.selectedLocations.splice(index, 1);
        this.selectedLocationName.splice(index, 1);
        this.clearNgSelect(this.dropDowns.length - 1);
        if (this.selectedLocations.length > 0) {
            this.userTags.get('location_ids').setValue(this.selectedLocations);
        } else {
            this.userTags.get('location_ids').setValue('');
        }
    }

    checkUserTagsOnLevel(level) {
        for (let i = 0; i < this.allottedLevel.length; i++) {
            if (this.allottedLevel[i].id === level) {
                this.allottedLevel.splice(i, 1);
                break;
            }
        }
    }

    openMessageDialog(message = 'On this level locations are allotted to this user'): void {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            width: '350px',
            data: {message: message}
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    selectAllPermission(event): void {
        if (event.checked) {
            this.getAppPermissions(this.userTags.get('data_level_id').value)
            this.selectedPermissions = this.allPermissions;
            this.userTags.get('app_permission_ids').setValue(this.allPermissions);
        } else {
            this.selectedPermissions = [];
            this.userTags.get('app_permission_ids').setValue(this.selectedPermissions);
        }
    }

    getUserTagDetails(userTagId): void {
        this.apiService.userTagDetails(userTagId, this.userTags.get('client_id').value).subscribe((response: any) => {
            this.selectedLocationName = this.utils.arrayOfKey(response.data.locations, 'name');
            this.selectedLocations = this.utils.arrayOfKey(response.data.locations, 'id');
            this.selectedLevelName = response.data.parents[0]?.name
            this.parentLocations = response.data.parents;
            this.isRbsTag = response.data.is_rbs_tag;
            this.userTags.get('is_rbs_tag').setValue(this.isRbsTag);
            this.selectedPermissions = this.utils.arrayOfKey(response.data.permission_ids, 'app_permission_id')
            this.userTags.get('app_permission_ids').setValue(this.selectedPermissions);
            this.userTags.get('location_ids').setValue(this.selectedLocations);
        }, error => {
            this.messageService.errorSnackBar(error);
        });
    }

    cancelTagCreation(): void {
        this.resetFormFields(false);
        this.userTagCreated.emit(true);
        this.clearNgSelect(0)
    }

    setValues(userTag, dataLevel): void {
        this.getAppDataLevel(this.userTags.get('client_id').value, dataLevel);
        this.getCountryStates();
        this.userTags.get('user_tag_id').setValue(userTag);
        this.userTags.get('data_level_id').setValue(dataLevel);
        this.getAppPermissions(dataLevel);
        this.getDataLevelHierarchy(dataLevel);
        this.getUserTagDetails(userTag);
    }

    clearNgSelect(currentIndex): void {
        for (let i = currentIndex; i < this.dropDowns.length; i++) {
            this.dropDowns[i] = null;
        }
    }

}
