<mat-card class="user-details mat-elevation-z0">
    <div class="row">
        <div class="{{details.class}}" *ngFor="let details of userDetails">
            <p class="mat-card-subtitle">{{details.label}}</p>
            <p class="mat-title" [matTooltip]="details.data">{{details.data}}</p>
            <ng-container *ngIf="details.button && !isDisabled">
                <button mat-button class="{{details.buttonClass}}"
                        (click)="actionOnUser(details.button)">{{details.button}}</button>
            </ng-container>
        </div>
        <div class="col-md-2">
            <div class="user-image col-md-3">
                <img [src]="croppedImage" class="rounded" alt=""
                     onerror="this.src='../../assets/images/error-image.png'"/>
            </div>
        </div>
    </div>
    <mat-slide-toggle class="example-margin" color="warn" [checked]="userDetail?.task_allowed" (change)="toggleUserTeamAddition()">
        {{userDetail?.task_allowed ? 'Disallow Team Addition' : 'Allow Team Addition'}}</mat-slide-toggle>
</mat-card>


