import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ConfirmDialogComponent} from "../../../shared/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {RestApiService} from "../../../services/rest-api.service";
import {MessageService} from "../../../services/message.service";
import {UtilsService} from "../../../services/utils.service";

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
    @Input() userDetail: any;
    userDetails: any;
    croppedImage: any = '';
    phone: any;
    @Input() mode: string;
    isDisabled = false;

    constructor(private router: Router, public dialog: MatDialog, private apiService: RestApiService,
                private messageService: MessageService, private utils: UtilsService) {
    }

    ngOnInit(): void {
        this.getUserDetails(this.userDetail)
    }

    getUserDetails(user: any): any {
        this.userDetails = [{
            label: 'Name',
            data: user.name,
            button: 'Edit Details',
            class: 'col-md-2',
            buttonClass: 'button'
        },
            {
                label: 'Phone No.',
                class: 'col-md-2',
                data: user.phone_number,
                button: 'Deactivate',
                buttonClass: 'deactivate-button'
            },
            {
                label: ' Email',
                data: user.email,
                class: 'col-md-3',
                button: 'Disable User',
                buttonClass: 'disable-button'
            },
            {label: 'User Name', class: 'col-md-2', data: user.username},
            {label: 'Gender', class: 'col-md-1', data: user.gender}];
        this.croppedImage = user.avatar;
        if (this.mode === 'view') {
            this.isDisabled = true;
        }
        this.phone = user.phone_number;
    }

    actionOnUser(name): void {
        if (name === 'Edit Details') {
            this.router.navigate(['/index/user'], {
                queryParams: {
                    phone: this.phone,
                    id: this.utils.userId
                }
            });
        } else if (name === 'Deactivate') {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                width: '350px',
                data: {action: name}
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.activateDeactivateUser(this.utils.userId);
                    this.router.navigate(['/index/user'], {
                        queryParams: {}
                    });
                }
            });
        } else {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                disableClose: true,
                width: '350px',
                data: {action: 'disable'}
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.enableDisableUser();
                    this.router.navigate(['/index/user'], {
                        queryParams: {}
                    });
                }
            });
        }
    }

    enableDisableUser(): void {
        this.apiService.toggleUser(this.utils.userId).subscribe(response => {
            const res = response as any;
            this.messageService.closableSnackBar(res.message)

        }, error => {
            this.messageService.errorSnackBar(error.err.message, 5000);
        })
    }

    activateDeactivateUser(userId): void {
        this.apiService.toggleActivateUser({userId}).subscribe(response => {
            const res = response as any;
            this.messageService.closableSnackBar(res.message)

        }, error => {
            this.messageService.errorSnackBar(error.err.message, 5000);
        })
    }

    toggleUserTeamAddition(): void {
        this.apiService.toggleTeamAddition(this.utils.userId).subscribe(response => {
            const res = response as any;
            this.userDetail.task_allowed = res.data;
            this.messageService.closableSnackBar(res.message)
        }, error => {
            this.messageService.errorSnackBar(error.err.message, 5000);
        })

    }

}
