<ng-container *ngIf="!loader">
    <form [formGroup]="userTags">
        <div class="level-select d-flex justify-content-between flex-wrap mt-1">
            <div class="selected-app w-100 d-flex align-items-center mb-3 justify-content-between z-0" *ngIf="isRbsTag">
                <p class="alert-danger mt-2 d-flex align-items-center"><mat-icon matPrefix class="info-icon">info</mat-icon>
                    {{ 'This Client App is managed by the RBS. If you change it, the RBS  won\'t handle it anymore' |translate}}</p>
            </div>
            <ng-container *ngIf="mode !== 'view'">
                <mat-label class="form-group-title d-flex w-100 align-items-center">{{'Select Level'|translate}}
                    <hr class="ml-3 col">
                </mat-label>
                <mat-form-field appearance="outline" class="form-field mt-1" [style.FontSize.px]="11">
                    <mat-icon class="location-icon" svgIcon="location" matPrefix></mat-icon>
                    <mat-icon class="location-icon" svgIcon="select-arrow" matSuffix></mat-icon>
                    <mat-select formControlName="data_level_id" disableOptionCentering placeholder="level">
                        <mat-option *ngFor="let option of appDataLevels"
                                    [value]="option.id">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
        </div>
        <ng-container *ngIf="userTags.get('data_level_id').value && mode !== 'view'">
            <div class="d-flex flex-wrap mt-1">
                <mat-label class="form-group-title w-100">{{'Select Location'|translate}}
                    <hr class="ml-3">
                </mat-label>
                <div class="form-field mr-3 mt-2 mb-3" *ngFor="let hierarchy of levelHierarchy; let i = index">
                    <ng-select [items]="locationsDropdown[hierarchy.location_type]" bindValue="id" bindLabel="name"
                               placeholder="{{'Select ' + hierarchy.name}}"
                               [disabled]="!locationsDropdown[hierarchy.location_type]"
                               appearance="outline" class="form-field" [style.FontSize.px]="11"
                               (change)="getRequiredLocation(hierarchy.location_type, $event, hierarchy.required_location_type, i)"
                               [clearable]="false" [(ngModel)]="dropDowns[i]" [ngModelOptions]="{standalone: true}"
                               [searchFn]="utils.customSearchFn">
                        <ng-template ng-option-tmp let-item="item">
                            <label class="options">{{item.number ? item.number + ' - ' + item.name : item.name}}</label>
                        </ng-template>
                        <ng-template ng-label-tmp let-item="item"><label
                                class="options">{{item.number ? item.number + ' - ' + item.name : item.name}}</label>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </ng-container>
        <ng-container>
            <div class="selected-location-container d-flex">
                <div class="d-flex flex-wrap mb-3" *ngIf="selectedLocationName?.length">
                    <ng-container *ngIf="parentLocations?.length">
                        <div class="d-flex flex-column justify-content-start"
                             *ngFor="let item of parentLocations; let i = index">
                            <p class="location-title">{{item.header  |translate}}</p>
                            <mat-chip-list class="chip-list mb-3">
                                <mat-chip [matTooltip]="item.name" #tooltip="matTooltip"
                                          matTooltipClass="tooltip">{{item.number ? item.number + ' - ' : ''}}{{item.name | trumcateText: 20}}
                                </mat-chip>
                            </mat-chip-list>
                        </div>
                    </ng-container>
                    <div class="d-flex flex-column justify-content-start"
                         *ngFor="let item of selectedLocationName; let i = index" (click)="tooltip.toggle()">
                        <p class="location-title">{{selectedLevelName  |translate}}</p>
                        <mat-chip-list class="chip-list mb-3">
                            <mat-chip [matTooltip]="item.name" #tooltip="matTooltip"
                                      matTooltipClass="tooltip">{{item.number ? item.number + ' - ' : ''}}{{item | trumcateText: 20}}
                                <button matChipRemove (click)="remove(i)" *ngIf="mode !== 'view'">
                                    <mat-icon class="chip-icon" svgIcon="cross"></mat-icon>
                                </button>
                            </mat-chip>
                        </mat-chip-list>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="userTags.get('data_level_id').value && !isLoading">
            <div class="d-flex align-items-center justify-content-between">
                <mat-label class="form-group-title w-100">{{'Permission'|translate}}
                    <hr class="col ml-3 w-75">
                    <mat-checkbox class="example-margin col-md-2"
                                  [checked]="selectedPermissions.length === allPermissions.length"
                                  (change)="selectAllPermission($event)"
                                  *ngIf="mode !== 'view'">{{'Select All'|translate}}</mat-checkbox>
                </mat-label>
            </div>
            <div class="d-flex flex-wrap">
                <div class="expansion-panel d-flex flex-wrap w-100" *ngFor="let group of appPermissions | keyvalue">
                    <ng-container *ngFor="let permission of group.value | keyvalue">
                        <label class="permission-name w-100 mt-3 mb-2">{{permission.key}}</label>
                        <ng-container *ngFor="let option of getValue(permission.value) ">
                            <mat-checkbox class="single-checkbox d-flex min-w-[20%] mb-2"
                                          (change)="permissionSelection($event, option.id)"
                                          [checked]="selectedPermissions?.includes(option.id)"
                                          [disabled]="mode === 'view'">
                                {{option.action}}
                            </mat-checkbox>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="button-container d-flex justify-content-end mb-4 mt-3" *ngIf="mode !== 'view'">
                <button mat-flat-button class="cancel-button ml-3"
                        (click)="cancelTagCreation()">{{'Cancel' | translate}}</button>
                <button mat-button class="button ml-3" [ngClass]="userTags.valid ? 'button': 'disabled'"
                        [disabled]="userTags.invalid || isLoading"
                        (click)="addUserTags()">{{'Save' | translate}}</button>
            </div>
        </ng-container>
    </form>
</ng-container>
<ng-container *ngIf="loader">
    <app-loader [loading]="loader"></app-loader>
</ng-container>
