<div class="container user-profile-container">
    <mat-card class="user-details  w-100 d-flex flex-wrap align-items-center">
        <div>
            <img [src]="croppedImage" class="user-image " alt=""
                 onerror="this.src='assets/images/add-user.png'"/>
        </div>
        <div>
            <mat-card-title class="user-name w-90">{{userName}}</mat-card-title>
            <mat-card-subtitle class="user-location w-40">{{userRoles.replaceAll('_', ' ')}}</mat-card-subtitle>
        </div>

    </mat-card>
    <mat-card class="user-apps-container mt-3">
        <div class="d-flex w-50 flex-wrap mt-3" *ngFor="let element of userDetails">
            <h3 class="details-name w-50">{{element.label}} : </h3>
            <p class="info">{{element.value}}</p>
        </div>
        <div class="d-flex justify-content-between align-items-center w-100 mt-3">
            <div class="w-24 ">
                <h3 class="details-name whitespace-nowrap">Allotted Locations : </h3>
            </div>
            <div class="d-flex flex-wrap w-75">
                <ng-container *ngFor="let element of states">
                    <p class="info flex-wrap"> {{element.name + (states.length < 2 ? '' : ',')}}</p>
                </ng-container>
            </div>
        </div>
    </mat-card>
    <mat-card class="d-flex justify-content-start flex-wrap mt-3 user-apps-container">
        <h3 class="title w-100 mb-3 mt-2">Client Apps:</h3>
        <mat-card *ngFor="let app of clientApps" class="d-flex user-app mt-3" title="{{app.name}}">
            <img [src]="app.app_icon" alt="icon"/>
            <div class="info">{{app.name}}</div>
        </mat-card>
        <mat-card class="d-flex user-app w-100" *ngIf="!clientApps?.length">
            <h3 class="title">{{'No client apps assigned to you'}}</h3>
        </mat-card>
    </mat-card>
</div>
