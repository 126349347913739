import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {RestApiService} from "../../../services/rest-api.service";
import {MessageService} from "../../../services/message.service";
import {ConfirmDialogComponent} from "../../../shared/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {UtilsService} from "../../../services/utils.service";
import {ManageUserComponent} from "../../manage-user/manage-user.component";

@Component({
    selector: 'app-user-table',
    templateUrl: './user-table.component.html',
    styleUrls: ['./user-table.component.css']
})
export class UserTableComponent implements OnInit {
    @Input() users: any;
    @Input() offset: number;
    @Input() limit: number;
    @Input() pageSize: number;
    @Input() pageJumpSize: number;
    @Input() length: any;
    @Input() isLoading: any = false;
    @Input() pageNumber: number;
    @Input() pageSizeOptions: number[];
    @Output() userAction = new EventEmitter();
    @Output() pagination = new EventEmitter();
    @Output() userCount = new EventEmitter();
    @Output() pageSizeChanged = new EventEmitter();
    action: string;

    constructor(private router: Router, private apiService: RestApiService, private messageService: MessageService,
                public dialog: MatDialog, private utils: UtilsService) {
    }

    ngOnInit(): void {
    }

    editUser(phone, id): void {
        this.utils.userId = id;
        this.router.navigate(['/index/user/details/' + id])
    }

    activateDeactivateUser(userId): void {
        this.apiService.toggleActivateUser({userId}, {showLoader: false}).subscribe(response => {
            const res = response as any;
            this.messageService.closableSnackBar(res.message)
            this.updateUserInformation(userId)
            // this.userAction.emit();

        }, error => {
            this.messageService.errorSnackBar(error.err.message, 5000);
        })
    }


    updateUserInformation(id) {
        const userIndex = this.users.findIndex(user => user.id === id)
        if (userIndex !== -1) {
            this.users[userIndex].active = !this.users[userIndex].active;
        }
    }

    openDialog(status, action, userId): void {
        if (action === 'disable' && status) {
            this.action = 'enable';
        } else if (action === 'disable' && !status) {
            this.action = 'disable';
        } else if (action === 'activate' && status) {
            this.action = 'Deactivate'
        } else if (action === 'activate' && !status) {
            this.action = 'Activate'
        }
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: false,
            width: '350px',
            data: {action: this.action}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (action === 'disable') {
                    this.enableDisableUser(userId);
                } else {
                    this.activateDeactivateUser(userId);
                }
            }
        });
    }

    enableDisableUser(userId): void {
        this.apiService.toggleUser(userId).subscribe(response => {
            const res = response as any;
            this.messageService.closableSnackBar(res.message)
            this.userAction.emit();

        }, error => {
            this.messageService.errorSnackBar(error.err.message, 5000);
        })
    }

    viewSsoDetails(userId): void {
        this.utils.userId = userId
        const dialogRef = this.dialog.open(ManageUserComponent, {
            disableClose: false,
            width: '980px',
            data: {action: this.action}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
            }
        });

    }

    nextPage() {
        this.pageNumber = this.pageNumber + 1;
        this.pagination.emit(this.pageNumber);
    }

    prevPage() {
        this.pageNumber = this.pageNumber - 1;
        this.pagination.emit(this.pageNumber);
    }

    userListCount() {
        this.userCount.emit(this.pageNumber);
    }
}
