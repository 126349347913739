<div class="container-lg" [ngClass]="isLoading ? 'cursor-wait': ''">
    <div class="breadcrumb-container mt-2">
        <app-breadcrumb></app-breadcrumb>
    </div>
    <mat-card class="add-user-card mt-1">
        <div class="label mb-3" *ngIf="!editMode">
            <h4 class="text"> Add New User</h4>
        </div>
        <form [formGroup]="createUser">
            <ng-container *ngIf="!editMode">
                <div class="d-flex row">
                    <mat-form-field appearance="outline" class="col-md-4" [style.fontSize.px]="12">
                        <mat-label>{{'Phone Number'|translate}}</mat-label>
                        <input matInput type="text" [minlength]="10" [maxlength]="10" contenteditable="true"
                               placeholder="{{'Enter Phone Number'|translate}}" name="phone"
                               formControlName="phone_number"  (keydown)="utils.isNumber($event)" required>
                        <mat-error class="modal-error" *ngIf="createUser.get('phone_number').hasError('pattern')">{{'Please enter a valid phone no.'|translate}}</mat-error>
                        <mat-error class="modal-error" *ngIf="createUser.get('phone_number').hasError('required')">{{'phone no. is required'|translate}}</mat-error>
                    </mat-form-field>
                    <div class="col-md-1">
                        <button mat-button

                                [ngClass]="createUser.get('phone_number').valid ? 'button mt-1' : 'button disabled'"
                                (click)="getUserDetails(createUser.get('phone_number').value)"
                                [disabled]="disabled">
                            {{'Submit'|translate}}</button>
                    </div>
                </div>
                <mat-label class="user-information">{{message}}</mat-label>
            </ng-container>
            <ng-container *ngIf="!message?.includes('not authorized')">
                <div class="label mb-3 mt-1">
                    <h4 class="text">{{'Edit Details' |translate}}</h4>
                </div>
                <div class="forms row">
                    <div class="col-md-9">
                        <div class="row">
                            <ng-container *ngIf="editMode">
                                <mat-form-field appearance="outline" class="col-md-5 mt-3" [style.fontSize.px]="12">
                                    <mat-label>{{'Phone Number'|translate}}</mat-label>
                                    <input matInput type="text" [minlength]="10" [maxlength]="10"
                                           placeholder="{{'Enter Phone Number'|translate}}" name="phone"
                                           [formControl]="phone" (keydown)="utils.isNumber($event)" required>
                                    <mat-error class="modal-error" *ngIf="phone.hasError('pattern')">{{'Please enter a valid phone no.'|translate}}</mat-error>
                                    <mat-error class="modal-error" *ngIf="phone.hasError('required')">{{'Phone no. is required'|translate}}</mat-error>
                                </mat-form-field>
                            </ng-container>

                            <mat-form-field appearance="outline" class="col-md-5" [style.fontSize.px]="12">
                                <mat-label>{{'Name'|translate}}</mat-label>
                                <input matInput type="text" formControlName="name" placeholder="{{'Name'|translate}}"
                                       name="phone"
                                       required>
                                <mat-error class="modal-error" *ngIf="createUser.get('name').hasError('required')">{{'Name is required'|translate}}</mat-error>
                                <mat-error class="modal-error" *ngIf="createUser.get('name').hasError('pattern')">{{'Please enter valid name'|translate}}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="col-md-5" [style.fontSize.px]="12">
                                <mat-label>{{'Email'|translate}}</mat-label>
                                <input matInput type="email" formControlName="email"
                                       placeholder="{{'Enter Email'|translate}}"
                                       name="email"
                                       required>
                                <mat-error class="modal-error" *ngIf="createUser.get('email').hasError('pattern')">{{'Please enter valid email'|translate}}</mat-error>
                                <mat-error class="modal-error" *ngIf="createUser.get('email').hasError('required')">{{'Email is required'|translate}}</mat-error>
                            </mat-form-field>
                            <ng-container *ngIf="!editMode">
                                <mat-form-field appearance="outline" class="col-md-5"
                                                [style.fontSize.px]="12">
                                    <mat-label>{{'Password'|translate}}</mat-label>
                                    <input matInput id="password" [type]="hide ? 'password' : 'text'"
                                           name="password" placeholder="{{'Password'|translate}}" formControlName="password">
                                    <mat-icon matSuffix class="cursor-pointer"
                                              (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    <mat-error class="modal-error"
                                               *ngIf="createUser.get('password').hasError('required')">{{'Password is required'|translate}}</mat-error>
                                    <mat-error class="modal-error" *ngIf="createUser.get('password').hasError('minlength')">
                                        {{'Password should be at least 8 characters'|translate}}
                                    </mat-error>
                                    <mat-error class="modal-error" *ngIf="createUser.get('password').hasError('pattern') && createUser.get('password').value.length > 5">
                                        {{'Password should contain 1 uppercase and 1 lowercase and 1 special characters and 1 Number'|translate}}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>

                            <div class="col-md-5 mt-3">
                                <mat-label class="mt-3 form-label">Gender</mat-label>
                                <mat-button-toggle-group name="fontStyle" aria-label="Gender" formControlName='gender'>
                                    <mat-button-toggle value="male">{{'Male'|translate}}</mat-button-toggle>
                                    <mat-button-toggle value="female">{{'Female'|translate}}</mat-button-toggle>
                                    <mat-button-toggle value="other">{{'Other'|translate}}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                            <mat-form-field appearance="outline" class="col-md-5 mt-3" [style.fontSize.px]="12">
                                <mat-label>{{'User Name'|translate}}</mat-label>
                                <input matInput type="text" formControlName="username"
                                       placeholder="{{'User Name'|translate}}">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="user-image col-md-3">
                        <mat-icon class="delete-icon position-absolute cursor-pointer" svgIcon="delete" *ngIf="croppedImage" (click)="clearImage()"></mat-icon>
                        <img [src]="croppedImage" width="130" class="rounded" height="125" alt=""
                             onerror="this.src='../../assets/images/add-user.png'"/>
                        <br>
                        <label class="select-image">
                            <input type="file" (change)="onFileChange($event, 'photo')"
                                   accept="image/png, image/jpeg, image/jpg"/>
                            {{'Select Image' | translate}}
                        </label>
                    </div>
                </div>
                <div class="w-100 d-flex justify-content-center mt-5">
                    <button mat-button type="submit" class="button"
                            [ngClass]="createUser.valid ? 'button': 'button disabled'" (click)="createNewUser()"
                            [disabled]="createUser.invalid || isLoading">
                        <mat-icon class="spinner-grow spinner-border mat-progress-spinner spinner-icon"
                                  *ngIf="isLoading">
                            <mat-spinner color="primary" diameter="20">
                            </mat-spinner>
                        </mat-icon>
                        {{buttonText |translate}}</button>
                </div>
            </ng-container>
        </form>
    </mat-card>
</div>
