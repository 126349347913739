import {NgModule} from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {IndexComponent} from "./user-management/index/index.component";
import {AddUserComponent} from "./user-management/add-user/add-user.component";
import {ManageUserComponent} from "./user-management/manage-user/manage-user.component";
import {ErrorPageComponent} from "./shared/error-page/error-page.component";
import {UserProfileComponent} from "./user-profile/user-profile.component";
import {DipRulesComponent} from "./dip-rules/dip-rules.component";
import {CreateDipRulesComponent} from "./create-dip-rules/create-dip-rules.component";
import {AuthGuard} from "./auth.guard";

const routes: Routes = [
  {
    path: 'index',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Home',
    },
    children: [
      {
        path: '',
        component: IndexComponent,
      },
      {
        path: 'user',
        data: {
          breadcrumb: 'Add User',
        },
        children: [
          {
            path: '',
            component: AddUserComponent,
          },
          {
            path: 'details/:id',
            component: ManageUserComponent,
            data: {
              breadcrumb: 'Assign Permissions',
            },
          }
        ]
      },
      {
        path: 'rule-based-access-management',
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Rule based access management',
        },
        children: [
          {
            path: '',
            component: DipRulesComponent,

          },
          {
            path: 'create-rules',
            component: CreateDipRulesComponent,
            data: {
              breadcrumb: 'Create Rules',
            },
          }
        ]
      }]
  },
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    pathMatch: 'full',
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    canActivate: [AuthGuard],
  },

 ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
