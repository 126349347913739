<div *ngIf="!viewMode">
    <div class="breadcrumb-container container-md mt-2">
        <app-breadcrumb></app-breadcrumb>
    </div>

    <div class="container-md">
        <app-user-details [userDetail]="userDetails" *ngIf="userDetails?.active"></app-user-details>
        <mat-card class="add-user-card mt-1 mat-elevation-z0 mb-1">
            <div class="label mb-1">
                <h4 class="text">{{'Assign Permissions'}}</h4>
            </div>
        </mat-card>
    </div>
    <hr class="col-12">

    <app-app-assignment *ngIf="manageUser" [mode]="'edit'"></app-app-assignment>
</div>

<ng-container *ngIf="viewMode">
    <div class="label d-flex justify-content-between mat-dialog-title" mat-dialog-title>
        <div class="d-flex align-items-center">
      <span class="text mr-3.5">
        <ng-container *ngIf="ssoDetails else titleTemplate">{{'SSO Details'}}</ng-container>
        <ng-template #titleTemplate>{{'Role Details'}}</ng-template></span>
            <button class="details-button ml-3" (click)="changeDetailsType()">
                <ng-container *ngIf="ssoDetails else roleTemplate">{{'View Role Details'}}</ng-container>
                <ng-template #roleTemplate>{{'View SSO Details'}}</ng-template>
            </button>
        </div>
        <mat-icon style="color: red; margin-right: 10px" mat-dialog-close="">close</mat-icon>
    </div>
    <mat-dialog-content class="p-0">
        <app-user-details [userDetail]="userDetails" *ngIf="manageUser" [mode]="'view'"></app-user-details>
        <hr class="col-12">
        <app-app-assignment *ngIf="manageUser && ssoDetails" [mode]="'view'"></app-app-assignment>
        <app-view-roles *ngIf="!ssoDetails"></app-view-roles>
    </mat-dialog-content>
</ng-container>

